import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { Body, Header } from './Text'
import Markdown from './Markdown'

function Chevron({ className, onClick }) {
  return (
    <svg
      onClick={onClick}
      width="44"
      height="28"
      viewBox="0 0 44 28"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M42.6434 1.55667C44.4522 3.29889 44.4522 6.12358 42.6434 7.8658L28.55 21.4409L22 27.75L15.45 21.4409L1.35656 7.8658C-0.452186 6.12358 -0.452186 3.29889 1.35656 1.55667C3.1653 -0.185555 6.09786 -0.185555 7.9066 1.55666L22 15.1317L36.0934 1.55666C37.9021 -0.185555 40.8347 -0.185555 42.6434 1.55667Z" />
    </svg>
  )
}

function DropdownTitle({ key, title, body, className }) {
  const [isOpen, setOpen] = useState(false)
  const toggle = useCallback(() => setOpen(!isOpen), [isOpen, setOpen])
  return (
    <div
      key={key}
      className={classNames('flex w-full flex-col gap-4', className)}
    >
      <div className="flex flex-row justify-between">
        <Header className="w-full cursor-pointer" onClick={toggle}>
          {title}
        </Header>
        <Chevron
          className={classNames(
            'scale-50 cursor-pointer fill-white transition-all duration-200 hover:fill-primary active:fill-primary 2xl:scale-100',
            { 'rotate-180 fill-primary': isOpen }
          )}
          onClick={toggle}
        />
      </div>
      <Body
        className={classNames(
          { hidden: !isOpen },
          'transition-all duration-500'
        )}
      >
        <Markdown>{body}</Markdown>
      </Body>
    </div>
  )
}

export default DropdownTitle
