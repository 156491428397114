import React, { useCallback, useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { SupportHeader } from '../components/sectionHeader'
import HighlightSection from '../components/homeSections/HighlightSection'
import { getImage } from '../utils/gatsby-strapi-helpers'
import { Body, Caption, SpecialHeader2 } from '../components/shared/Text'
import DropdownTitle from '../components/shared/DropdownTitle'
import Markdown from '../components/shared/Markdown'
import { LabeledInput2 } from '../components/shared/Forms'
import { MainButtonWide } from '../components/shared/Buttons'
import { TitleWithRightDecoration } from '../components/TitleWithDecoration'
import titlesApi from '../utils/titlesApi'
import WithReCaptcha from '../hocs/withRecaptcha'
import useMessages from '../hooks/useMessages'

const FaqElement = ({
  id,
  title,
  description: {
    data: { description },
  },
  className,
}) => (
  <DropdownTitle
    key={id}
    title={title}
    body={description}
    className={className}
  />
)

function Form({
  contactFieldName,
  contactFieldEmail,
  contactFieldMessage,
  submitText,
  getCaptchaToken,
}) {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState()

  const { register, handleSubmit, formState, reset, getValues } = useForm({
    mode: 'all',
  })

  const resetForm = useCallback(() => {
    reset()
  }, [reset])

  const timerRef = useRef(null)
  const onSubmit = useCallback(async () => {
    const reCaptcha = await getCaptchaToken()
    try {
      setIsLoading(true)
      const r = await axios.post(titlesApi('/mailing/contact'), {
        titleId: process.env.GATSBY_TITLE_ID,
        replyTo: getValues('email'),
        name: getValues('name'),
        message: getValues('message'),
        reCaptcha,
      })
      setResponse(r)
      timerRef.current = setTimeout(() => setResponse(null), 1000)
      resetForm()
    } catch (e) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [getCaptchaToken, resetForm, getValues])

  // Clear the interval when the component unmounts
  useEffect(() => () => clearTimeout(timerRef.current), [])

  const msg = useMessages()

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <LabeledInput2
        name="name"
        register={register}
        opts={{ required: true }}
        label={contactFieldName}
        formState={formState}
      />

      <LabeledInput2
        name="email"
        type="email"
        register={register}
        opts={{ required: true }}
        label={contactFieldEmail}
        formState={formState}
      />

      <LabeledInput2
        name="message"
        type="textarea"
        register={register}
        opts={{ required: true }}
        label={contactFieldMessage}
        formState={formState}
      />

      <MainButtonWide
        type="submit"
        loading={isLoading}
        success={!!response && !isLoading && !isError}
      >
        {submitText}
      </MainButtonWide>
      {isError && <Caption className="text-primary">{msg('error')}</Caption>}
    </form>
  )
}

const FormWithRecaptcha = WithReCaptcha(Form)

const Support = ({ pageContext, data }) => {
  const { strapiSupport, allStrapiFaq } = data

  const {
    title,
    description: {
      data: { description },
    },
    picture,
    faqsTitle,
    contactTitle,
    contactDescription: {
      data: { contactDescription },
    },
    contactFieldName,
    contactFieldEmail,
    contactFieldMessage,
    // TODO: watch out this was renamed but didn't rename here
    submitText,
  } = strapiSupport

  const seo = {
    metaTitle: title,
    // TODO: how to get just the text without markdown symbols?
    metaDescription: description,
  }

  const allFaqs = allStrapiFaq.nodes

  return (
    <Layout pageContext={pageContext} navbarActive="support">
      <Seo seo={seo} />
      <HighlightSection
        className="lg:!h-3/4"
        header={<SupportHeader title={title} paragraph={description} />}
        staticImage={
          <GatsbyImage
            image={getImage(picture)}
            alt={title}
            layout="fullWidth"
            className="max-h-[70vh]"
            backgroundColor="#161616"
          />
        }
      />
      <div className="container">
        <div className="lg:px-16">
          <SpecialHeader2 className="pt-10 pb-4 text-center">
            {faqsTitle}
          </SpecialHeader2>
          <div className="flex flex-col gap-10 pt-8">
            {allFaqs.map(faq => (
              <FaqElement {...faq} className="py-4" />
            ))}
          </div>
        </div>
      </div>
      <div className="container pt-32">
        <div className="flex flex-col gap-3 lg:gap-11">
          <SpecialHeader2 className="text-center lg:hidden">
            {contactTitle}
          </SpecialHeader2>
          <div className="hidden overflow-hidden lg:block">
            <TitleWithRightDecoration>{contactTitle}</TitleWithRightDecoration>
          </div>
          <div className="flex flex-col gap-6 lg:grid lg:grid-cols-2 lg:gap-32">
            <Body>
              <Markdown>{contactDescription}</Markdown>
            </Body>
            <div className="flex flex-col items-center lg:items-start">
              <FormWithRecaptcha
                contactFieldName={contactFieldName}
                contactFieldEmail={contactFieldEmail}
                contactFieldMessage={contactFieldMessage}
                submitText={submitText}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($locale: String) {
    strapiSupport(locale: { eq: $locale }) {
      title
      description {
        data {
          description
        }
      }
      picture {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      faqsTitle
      contactTitle
      contactDescription {
        data {
          contactDescription
        }
      }
      contactFieldName
      contactFieldEmail
      contactFieldMessage
      # TODO: watch out this was renamed but didn't rename here
      submitText
    }

    allStrapiFaq(
      filter: { locale: { eq: $locale } }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        id
        title
        description {
          data {
            description
          }
        }
      }
    }
  }
`

export default Support
